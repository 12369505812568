<template>
  <div class="home">
    <div class="langs">
        <a href="#" @click.prevent="reload('fr');" :class="lang=='fr'?'active':''">FR</a>
        <a @click.prevent="reload('en');" :class="lang=='en'?'active':''">EN</a>
    </div>
    <header>
    <div class="background" style="background-image: url(./assets/bourbo.jpg)"></div>
    <h1>{{title[lang]}}</h1>
    <p class="description">{{description[lang]}}</p>
    <p>{{hours[lang]}}</p>
    </header>
    <div class="nav">
      <span v-for="drink,name in drinks" :key="name">
        <a :href="'#'+ classify(drink)">
          {{drinkslang[name][lang]}}
        </a>
      </span>
    </div>
    <div v-for="drink,name in drinks" :key="name" class="carte" :id="classify(drink)">
      <h2 :class="drink">{{drinkslang[name][lang]}}</h2>
      <table class="table table-bordered table-hover">
        <tbody v-for="item in xmlItems[drink]" :key="item.id">
          <tr>
          <td v-if="item.name">{{item.name}} {{item.degree}}<span v-if="item.degree">°</span></td>
          <td :class="item.name?'':'no-name'"><span v-for="price,k in item.prices" :key="k">
            <span v-for="detail,d in price" :key="d"><small v-if="detail['$']['id']">{{detail['$']['id']}}{{item.su}}</small><span v-if="detail['_']">{{detail['_']}}</span></span>
          </span></td>
          </tr>
          <tr v-if="item.details" class="details"><td colspan="2">{{item.details}}</td></tr>
        </tbody>
      </table>
    </div>

    <footer class="partners" v-if="lang=='fr'">
      <div class="background" style="background-image: url(./assets/bourbo2.jpg)"></div>
      <div>En partenariat avec<br />
      <strong>La Filature</strong> 08460 Clavy-Warby<br />
      <strong>Aux Délices Laitiers</strong> 08460 Signy l’abbaye<br />
      <strong>Sellier La Croix de Ferrière</strong> 08430 Champigneul sur Vence<br />
      <strong>Aux Saveurs d’Ardennes</strong> 08000 La Francheville</div>
    </footer>
    <footer class="partners" v-if="lang=='en'">
      <div class="background" style="background-image: url(./assets/bourbo2.jpg)"></div>
      <div>Our partners<br />
      <strong>La Filature</strong> 08460 Clavy-Warby<br />
      <strong>Aux Délices Laitiers</strong> 08460 Signy l’abbaye<br />
      <strong>Sellier La Croix de Ferrière</strong> 08430 Champigneul sur Vence<br />
      <strong>Aux Saveurs d’Ardennes</strong> 08000 La Francheville</div>
    </footer>

    <div class="copyright">
      Copyright &copy; 2022 La Bourbonade
    </div>

  </div>
</template>

<script>
import axios from 'axios';
import xml2js from 'xml2js';
export default {
  name: 'HomePage',
  props: {
    msg: String
  },
  data() {
    return {
      xmlItems:{},
      xmlTitle: null,
      lang: 'fr',
      title: {'fr': 'Carte des boissons et Menu sur le pouce de la Bourbonade à Charleville-Mézières',
      'en': 'Drinks menu and Snacks menu of the Bourbonade in Charleville-Mézières, France'},
      description: {'fr':'Bar à bières, rhums, cocktails, et petite restauration',
      'en':'Bar with beers, rums, cocktails, and snacks'},
      hours: {'fr':'Du mardi au vendredi de 11h à 1h et le samedi de 15h à 1h',
      'en':'Tuesday to Friday from 11 a.m. to 1 a.m. and Saturday from 3 p.m. to 1 a.m.'},
      drinks: {
        '0' : 'drink.xml',
        '1' : 'drink2.xml',
        '2' : 'drink3.xml',
        '3' : 'drink4.xml',
        '4' : 'drink5.xml',
        '5' : 'drink6.xml',
        '6' : 'drink7.xml',
        '7' : 'drink8.xml',
        '8' : 'drink9.xml',
        '9' : 'drink10.xml',
        '10' : 'drink11.xml',
        '11' : 'eat.xml',
        '12' : 'eat2.xml',
        '13' : 'eat3.xml',
      },
      drinkslang: {
        '0' : {'fr' : 'Bières Pressions', 'en':'Draft beers'},
        '1' : {'fr' : 'Bières Bouteilles', 'en':'Bottled beers'},
        '2' : {'fr' : 'Apéritifs', 'en':'Aperitives'},
        '3' : {'fr' : 'Boissons Chaudes', 'en':'Hot drinks'},
        '4' : {'fr' : 'Sans Alcool', 'en':'Alcohol-free'},
        '5' : {'fr' : 'Alcool & Liqueurs', 'en':'Alcohol & Liquors'},
        '6' : {'fr' : 'Vins', 'en':'Wines'},
        '7' : {'fr' : 'Whisky', 'en':'Whisky'},
        '8' : {'fr' : 'Rhum / Arrangés', 'en':'Rum / Arranged rum'},
        '9' : {'fr' : 'Les Classiques Estivaux', 'en':'Summer Classics'},
        '10' : {'fr' : 'Cocktails', 'en':'Cocktails'},
        '11' : {'fr' : 'Sandwichs', 'en':'Sandwiches'},
        '12' : {'fr' : 'Planches d\'apéro', 'en':'Plancha'},
        '13' : {'fr' : 'Les Chauds', 'en':'Hot Sandwiches'},
      }
    }
  },

  methods: {

  reload(lang){
      this.lang = lang;
      for(let i in this.drinks)
       axios.get('/assets/' + this.lang + '/' + this.drinks[i])
         .then(response => {
           this.parseXML(response.data)
               .then((data) => {
               console.log(data);
               this.xmlItems[this.drinks[i]] = data[Object.keys(data)[0]];
               console.log(this.xmlItems);
               });
         })

     },
     classify(cclass) {
      return cclass.replace('.xml','');
     },
     switchXml(file) {
        axios.get('/assets/'+file)
          .then(response => {
            this.parseXML(response.data)
                .then((data) => {
                console.log(data, file);
                this.xmlItems[file] = data[Object.keys(data)[0]];

  console.log(this.xmlItems)
                });
          });


     },
     parseXML(data) {
        return  new Promise(resolve => {
             var k="";
              var arr = [], parser = new xml2js.Parser({ trim: true, explicitArray: true });
              let title;
           parser.parseString(data, function (err, result) {
             title = result.Shelf.$.title;
             var obj = result.Shelf;
             for (k in obj.item) {
               var item = obj.item[k];
               arr.push({
                 id: item.id[0],
                 name: item.name[0],
                 degree: item.degree[0],
                 su: item.su[0],
                 details: item.details[0],
                 prices: item.prices[0],

               });
             }
             let container = [];
             container[title] = arr;
             resolve(container);
           });
         });
     }
   },
  mounted(){
   //xml file calling
   for(let i in this.drinks)
    axios.get('/assets/' + this.lang + '/' + this.drinks[i])
      .then(response => {
        this.parseXML(response.data)
            .then((data) => {
            console.log(data);
            this.xmlItems[this.drinks[i]] = data[Object.keys(data)[0]];
            console.log(this.xmlItems);
            });
      })

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  table { text-align: left; margin: 0 auto; }
  h1 { max-width: 20em; line-height: 1.3em; margin: 1em auto; }
</style>
