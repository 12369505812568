<template>
  <!-- img alt="Vue logo" src="./assets/logo.png" -->
  <div class="app">
    <Home msg="" />
  </div>
</template>

<script>
import Home from './views/Home.vue'

export default {
  name: 'App',
  components: {
    Home
  }
}
</script>

<style>
@font-face {
    font-family: 'Morganite';
    src: url('assets/fnt/Morganite-Bold.woff2') format('woff2'),
        url('assets/fnt/Morganite-Bold.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Univers';
    src: url('assets/fnt/Univers-CondensedLightOblique.woff2') format('woff2'),
        url('assets/fnt/Univers-CondensedLightOblique.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

*{
scroll-behavior: smooth !important;
}
body {
 font-size: 1.2em;
 margin: 0;
}


#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 2em;
}

header, footer { padding: 5em 1em;  position:relative; background: #111111;margin-right: 0em; margin-left: 0em; margin-top: 2em;}
header h1 {
font-family: 'Morganite';
    font-size: 3em;
    padding: 0 0.4em!important;
    letter-spacing: .1em;}
header *, footer * { position: relative; z-index:2; color: #fcfcfc;}
header .background, footer .background {

z-index: 1;
    position: absolute;
    inset: 0;
    max-height: 100%;
    min-height: 0;
    background-position: 50% 27%;
    opacity: .61;
 background-size: cover;
}

header .background { background-position: 68% 50%; }

h1 {
padding: 1em;
font-size: 1.4em;
}

h2 {
  font-family: 'Morganite';
  font-weight: bold;
  font-size: 3em;
  letter-spacing: 0.06em;
}

p, table td {
  font-family: 'Univers';
}

a {
text-decoration: none; color: #444444;
padding: .2em .4em;
position: relative;
display: inline-block;
    border: 1px solid #777777;
}
.langs {font-size: .8em;}
.langs a { opacity: .6; cursor: pointer;}
.langs a { border: 0; border-bottom: 1px solid #777777; }
.langs a.active {
opacity: 1;
}
table { width: 100%; max-width: 20em; padding: 1em 0; }
table td + td { text-align: right; }
table td + td span {margin-left: .4em; }
table td + td small {margin-left: .2em; }
.home { padding-bottom: 3em; }
.nav { padding: 1em 4em; margin: 0 auto; }
.nav span { display: inline-block; padding: .6em 1em; }
.nav a {
    font-family: 'Univers';}
.langs a { border: 0; border-bottom: 1px solid #777777; }
.description {font-size: 1.2em;font-weight: bold;}
.details { opacity: .7; }
.carte { width: 100%; max-width: 20em; display: inline-block; margin: 1em;
  scroll-behavior: smooth;  }
#drink11 { display: block; margin-right: auto; margin-left: auto;}
.no-name {text-align: center;}
.no-name span, .no-name small { margin-left: 0;}.partners {
    padding: 4em 2em;
    margin: 4em 2em;
    text-transform: uppercase;
    letter-spacing: .06em;
    font-size: .75em;
    line-height: 1.6;
    font-family: 'Univers';
}
.partners strong { display: block; margin-top: 1em;}

footer.partners { margin-left: 0; margin-right: 0;}
footer .background { background-attachment: fixed;     background-position: 73% 50%; background-position: 68% 50%; }

.copyright {
font-size: .6em;
    padding: 10em;
    text-transfom: uppercase;
    text-transform: uppercase;
    letter-spacing: .16em;
    font-family: 'Univers';
 }

@media screen and (max-width:700px) {
body {
  font-size: 1em;
  }
  h1 {max-width: 12em!important;}
}
</style>
